exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-inviz-ai-solutions-jsx": () => import("./../../../src/pages/about-inviz-ai-solutions.jsx" /* webpackChunkName: "component---src-pages-about-inviz-ai-solutions-jsx" */),
  "component---src-pages-capabilities-artificial-intelligence-and-machine-learning-jsx": () => import("./../../../src/pages/capabilities/artificial-intelligence-and-machine-learning.jsx" /* webpackChunkName: "component---src-pages-capabilities-artificial-intelligence-and-machine-learning-jsx" */),
  "component---src-pages-capabilities-cloud-engineering-jsx": () => import("./../../../src/pages/capabilities/cloud-engineering.jsx" /* webpackChunkName: "component---src-pages-capabilities-cloud-engineering-jsx" */),
  "component---src-pages-capabilities-data-engineering-jsx": () => import("./../../../src/pages/capabilities/data-engineering.jsx" /* webpackChunkName: "component---src-pages-capabilities-data-engineering-jsx" */),
  "component---src-pages-careers-jsx": () => import("./../../../src/pages/careers.jsx" /* webpackChunkName: "component---src-pages-careers-jsx" */),
  "component---src-pages-case-studies-clickstream-data-platform-jsx": () => import("./../../../src/pages/case-studies/clickstream-data-platform.jsx" /* webpackChunkName: "component---src-pages-case-studies-clickstream-data-platform-jsx" */),
  "component---src-pages-case-studies-index-jsx": () => import("./../../../src/pages/case-studies/index.jsx" /* webpackChunkName: "component---src-pages-case-studies-index-jsx" */),
  "component---src-pages-case-studies-intelligent-product-discovery-platform-jsx": () => import("./../../../src/pages/case-studies/intelligent-product-discovery-platform.jsx" /* webpackChunkName: "component---src-pages-case-studies-intelligent-product-discovery-platform-jsx" */),
  "component---src-pages-case-studies-machine-learning-for-ai-powered-search-jsx": () => import("./../../../src/pages/case-studies/machine-learning-for-ai-powered-search.jsx" /* webpackChunkName: "component---src-pages-case-studies-machine-learning-for-ai-powered-search-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-terms-and-conditions-jsx": () => import("./../../../src/pages/terms-and-conditions.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-jsx" */)
}

